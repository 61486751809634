<div
    class="bg-info rounded p-4 d-flex flex-column flex-sm-row justify-content-center align-items-center gap-4 mt-4 mb-5">
    <img src="assets/icons/icon-shield-white.svg" alt="Shield with tick mark" role="presentation" aria-hidden="true"
        width="41" height="50">
    <p class="mb-0 text-white">
        You can also get comprehensive device insurance through Techsured.
        <a href="#"
            class="ps-1 d-inline-flex text-white align-items-center text-decoration-none border-bottom border-white">
            <span>Find out more</span>
            <i class="pi pi-fw pi-angle-right"></i>
        </a>
    </p>
</div>