import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'widget-product-card-group',
  templateUrl: './widget-product-card-group.component.html',
  styleUrls: ['./widget-product-card-group.component.scss']
})
export class WidgetProductCardGroupComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
