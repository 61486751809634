import { Component, OnInit } from '@angular/core';
import { Page } from 'ngx-techsured';
import { DialogService } from 'primeng/dynamicdialog';
import * as _ from 'lodash';
import { ModalCallMeBackComponent } from "src/app/modal/modal-call-me-back/modal-call-me-back.component";
import { DownloadableGql } from 'ngx-techsured';

@Component({
  selector: 'layout-page-public-simple',
  templateUrl: './layout-page-public-simple.component.html',
  styleUrls: ['./layout-page-public-simple.component.scss']
})
export class LayoutPagePublicSimpleComponent extends Page implements OnInit {

  downloadables:any[] | undefined;

  constructor(private readonly _downloadableGQL:DownloadableGql, _dialogService: DialogService) {
    super({ dialogService: _dialogService })
  }

  ngOnInit(): void {
    this._downloadableGQL.findAll().subscribe({
      next: ({ loading, data }) => {
        if (!loading) {
          this.downloadables = _.get(data, "downloadables.data");
          this.downloadables = _.map(this.downloadables, (item) => {
            const identifier = _.get(item, "attributes.identifier");
            let url = _.get(item, "attributes.asset.data.attributes.url");

            // Check for specific identifiers and replace the URL if necessary
            if (identifier === "pdf_privacy_policy") {
              url =
                "https://policies.ignitiongroup.co.za/uploads/Data_Privacy_Statement_6db0863242.pdf";
            } else if (identifier === "pdf_cookie_policy") {
              url =
                "https://policies.ignitiongroup.co.za/uploads/cookiepolicy_b36bc1bd41.pdf";
            } else if (identifier === "pdf_paia_manual") {
              url =
                "https://policies.ignitiongroup.co.za/uploads/PAIA_Manual_357300275e.pdf";
            }

            return {
              identifier: identifier,
              url: url,
            };
          });
        }
      },
    });
  }

  onCallMeBackClick(){
    const modalInst = this.openModal(ModalCallMeBackComponent, {
      data: {},
      styleClass: "modal-bg-dark modal-sm",
      modal: true
  })
  }

}
