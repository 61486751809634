import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'component-image',
  templateUrl: './component-image.component.html',
  styleUrls: ['./component-image.component.scss']
})
export class ComponentImageComponent implements OnInit, AfterViewInit {

  @Input() element: any;
  @Input() height: number = 284;
  @Input() width: number = 512;
  @Input() alt: string = 'Techsured Image'
  @Input() styleClass: string = "w-100 img-fluid rounded";
  @Input() loadingState: "lazy" | "eager" = "lazy";

  identifier: String | undefined;


  private _isSrcSet: boolean = false;
  private _srcset: string | undefined | SafeResourceUrl;
  private _src: string | undefined | SafeResourceUrl;

  constructor(private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.identifier = _.get(this.element, "Identifier");
    if (this._extractImageAttribute(this.element)) {
      console.debug(`ComponentImageComponent ${this.identifier} Items `, this._extractImageAttribute(this.element));
      this.element = this._extractImageAttribute(this.element);
    }
    this.render();
  }
  ngAfterViewInit(): void { }

  get src() {
    return this._src;
  }

  get srcset() {
    return this._srcset;
  }

  set src(src: string | undefined | SafeResourceUrl) {
    this._src = src;
  }

  set srcset(srcset: string | undefined | SafeResourceUrl) {
    this._srcset = srcset;
  }

  get isSrcSet(): boolean {
    return this._isSrcSet;
  }

  set isSrcSet(isSrcSet: boolean) {
    this._isSrcSet = isSrcSet;
  }

  private _extractImageAttribute(element: any) {
    return _.get(this.element, "Items.data");
  }
  render() {
    console.debug(`ComponentImageComponent element ${this.identifier} `, this.element);
    if (_.isArrayLikeObject(this.element)) {
      const images: string | SafeResourceUrl[] = _.map(this.element, (item) => {
        const imageUrl: string | undefined = _.get(item, "attributes.url");
        console.debug(`ComponentImageComponent ${this.identifier} image url: `, imageUrl);
        return (imageUrl) ? this.sanitizer.bypassSecurityTrustUrl(imageUrl) : imageUrl || "";
      });
      console.debug(`ComponentImageComponent ${this.identifier} maybe a src set `, _.size(images), " attributes ", images);
      if (_.size(images) > 1) {
        this.src = _.first(images);
        this.srcset = images.join(", ") + " 2x";
        this.isSrcSet = true;
        console.debug(`ComponentImageComponent ${this.identifier} this.isSrcSet `, this.isSrcSet, " this.src  ", this.src, " this.srcset ", this.srcset);
      } else if (_.size(images) === 1) {
        this.srcset = undefined;
        this.src = _.first(images);
        this.isSrcSet = false;
        console.debug(`ComponentImageComponent ${this.identifier} this.isSrcSet `, this.isSrcSet, " this.src  ", this.src, " this.srcset ", this.srcset);
      }
    }
  }
}
