import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[emphasisLastWord]'
})
export class EmphasisLastWordDirective implements OnInit, AfterViewInit {

  @Input() emphasisLastWord = '';
  @Input() offset: number = 1;

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) { }

  ngOnInit(): void {
    console.debug("ngOnInit emphasisLastWord ", this._elementRef.nativeElement);
    //<span class="text-secondary">Easy</span>
  }
  ngAfterViewInit(): void {
    let words: string[] = _.words(this._elementRef.nativeElement.innerText);
    console.debug("ngAfterViewInit emphasisLastWord ", this._elementRef.nativeElement.innerText, " words ", words);
    const lastWords: string[] | undefined = words.splice((words.length > this.offset) ? this.offset * -1 : -1);
    //<span class="text-secondary">Easy</span>
    //TODO: Add ability to change class to be used and html element to wrap last word in
    this._renderer.setProperty(this._elementRef.nativeElement, 'innerHTML', `${words.join(' ')}&nbsp;<span class="${this.emphasisLastWord || "text-secondary"}">${lastWords.join(" ")}</span>`);


  }
}
