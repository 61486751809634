import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(url: string): SafeResourceUrl;
  transform(urls: string[]): SafeResourceUrl[];
  transform(urlOrUrls: string | string[]): SafeResourceUrl | SafeResourceUrl[] {
    if (Array.isArray(urlOrUrls)) {
      return urlOrUrls.map(url => this.sanitizer.bypassSecurityTrustResourceUrl(url));
    } else {
      return this.sanitizer.bypassSecurityTrustResourceUrl(urlOrUrls);
    }
  }
}
