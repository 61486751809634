
<h2 class="fs-2d fw-bold text-white text-center mx-auto my-3">My <span class="text-secondary">Devices</span></h2>

<div class="px-4" style="min-width: 800px;">
    <div *ngFor="let device of devices">
        <div *ngIf="device['CategoryID'] == 1" class="d-flex flex-column flex-md-row align-items-center bg-blue rounded row mb-2">
            <h4 class="col-12 col-lg-4 fw-bold text-white mb-0 p-2">{{ device['Title'] }}</h4>
            <div class="col-12 col-lg-4 p-2 text-center text-md-start">
                <h4 class="fw-bold text-info mb-0" *ngIf="device['provider']['name'] == 'Techsured Plus'">
                    Techsured <span class="text-pink">Pro</span>
                </h4>
                <h4 class="fw-bold text-info mb-0" *ngIf="device['provider']['name'] == 'Techsured Screen-Protect'">
                    Screen <span class="text-pink">Protect</span>
                </h4>
                <h4 class="fw-bold text-info mb-0" *ngIf="device['provider']['name'] == 'Techsured Standard'">
                    Techsured <span class="text-pink">Standard</span>
                </h4>
                <h4 class="fw-bold text-info mb-0" *ngIf="device['provider']['name'] == 'Techsured Smart'">
                    Techsured <span class="text-pink">Smart</span>
                </h4>
                <p class="fs-small mb-0 text-white opacity-80">
                    {{ device['provider']['coverText'] }}
                </p>
            </div>
            <h3 class="col-12 col-lg-2 fw-normal text-white mb-0 ms-md-auto p-2 text-md-end">R{{device['deal']['DealPrice']}}pm</h3>
            <!-- <a role="button" class="tcol-12 col-lg-1 text-decoration-none d-flex justify-content-end">
                <i (click)="removeDeviceFromDevices(device)" class="pi pi-times text-info opacity-62"></i>
            </a> -->
        </div>
        <div *ngIf="device['CategoryID'] == 17" class="d-flex flex-column flex-md-row align-items-center bg-blue rounded row mb-2">
            <h4 class="col-12 col-lg-4 fw-bold text-white mb-0 p-2">{{ device['Title'] }}</h4>
            <div class="col-12 col-lg-4 p-2 text-center text-md-start">
                <h4 class="fw-bold text-info mb-0" *ngIf="device['provider']['name'] == 'Techsured Plus'">
                    Techsured <span class="text-pink">Pro</span>
                </h4>
                <h4 class="fw-bold text-info mb-0" *ngIf="device['provider']['name'] == 'Techsured Screen-Protect'">
                    Screen <span class="text-pink">Protect</span>
                </h4>
                <h4 class="fw-bold text-info mb-0" *ngIf="device['provider']['name'] == 'Techsured Standard'">
                    Techsured <span class="text-pink">Standard</span>
                </h4>
                <p class="fs-small mb-0 text-white opacity-80">
                    {{ device['provider']['coverText'] }}
                </p>
            </div>
            <h3 class="col-12 col-lg-2 fw-normal text-white mb-0 ms-md-auto p-2 text-md-end">R{{device['deal']['DealPrice']}}pm</h3>
            <!-- <a role="button" class="tcol-12 col-lg-1 text-decoration-none d-flex justify-content-end">
                <i (click)="removeDeviceFromDevices(device)" class="pi pi-times text-info opacity-62"></i>
            </a> -->
        </div>
        <div *ngIf="device['CategoryID'] == 16" class="d-flex flex-column flex-md-row align-items-center bg-blue rounded row mb-2">
            <h4 class="col-12 col-lg-4 fw-bold text-white mb-0 p-2">{{ device['Title'] }}</h4>
            <div class="col-12 col-lg-4 p-2 text-center text-md-start">
                <h4 class="fw-bold text-info mb-0" *ngIf="device['provider']['name'] == 'Techsured Plus'">
                    Techsured <span class="text-pink">Pro</span>
                </h4>
                <h4 class="fw-bold text-info mb-0" *ngIf="device['provider']['name'] == 'Techsured Screen-Protect'">
                    Screen <span class="text-pink">Protect</span>
                </h4>
                <h4 class="fw-bold text-info mb-0" *ngIf="device['provider']['name'] == 'Techsured Standard'">
                    Techsured <span class="text-pink">Standard</span>
                </h4>
                <p class="fs-small mb-0 text-white opacity-80">
                    {{ device['provider']['coverText'] }}
                </p>
            </div>
            <h3 class="col-12 col-lg-2 fw-normal text-white mb-0 ms-md-auto p-2 text-md-end">R{{device['deal']['DealPrice']}}pm</h3>
            <!-- <a role="button" class="tcol-12 col-lg-1 text-decoration-none d-flex justify-content-end">
                <i (click)="removeDeviceFromDevices(device)" class="pi pi-times text-info opacity-62"></i>
            </a> -->
        </div>
        <div *ngIf="device['CategoryID'] == 18" class="d-flex flex-column flex-md-row align-items-center bg-blue rounded row mb-2">
            <h4 class="col-12 col-lg-4 fw-bold text-white mb-0 p-2">{{ device['Device'] }}</h4>
            <div class="col-12 col-lg-4 p-2 text-center text-md-start">
                <h4 class="fw-bold text-info mb-0">
                    Techsured <span class="text-pink">Decoder</span>
                </h4>
                <p class="fs-small mb-0 text-white opacity-80">
                    {{ device['provider']['coverText'] }}
                </p>
            </div>
            <h3 class="col-12 col-lg-2 fw-normal text-white mb-0 ms-md-auto p-2 text-md-end">R{{device['deal']['DealPrice']}}pm</h3>
            <!-- <a role="button" class="col-12 col-lg-1 text-decoration-none d-flex justify-content-end">
                <i (click)="removeDeviceFromDevices(device)" class="pi pi-times text-info opacity-62"></i>
            </a> -->
        </div>
        <div *ngIf="device['CategoryID'] == 'home'" class="d-flex flex-column flex-md-row align-items-center bg-blue rounded row mb-2">
            <h4 class="col-12 col-lg-4 fw-bold text-white mb-0 p-2">{{ device['deal']['cover_text'] }}</h4>
            <div class="col-12 col-lg-4 p-2 text-center text-md-start">
                <h4 class="fw-bold text-info mb-0">
                    Accidental <span class="text-pink">Damage</span>
                </h4>
                <p class="fs-small mb-0 text-white opacity-80">
                    Cover for multiple devices in your home
                </p>
            </div>
            <h3 class="col-12 col-lg-2 fw-normal text-white mb-0 ms-md-auto p-2 text-md-end">{{device['deal']['pm_amount_text']}}pm</h3>
            <!-- <a role="button" class="col-12 col-lg-1 text-decoration-none d-flex justify-content-end">
                <i (click)="removeDeviceFromDevices(device)" class="pi pi-times text-info opacity-62"></i>
            </a> -->
        </div>
        <div *ngIf="device['CategoryID'] == 'New'" class="d-flex flex-column flex-md-row align-items-center bg-blue rounded row mb-2">
            <h4 class="col-12 col-lg-4 fw-bold text-white mb-0 p-2">{{ device['Title'] }}</h4>
            <div class="col-12 col-lg-4 p-2 text-center text-md-start">
                <h4 class="fw-bold text-info mb-0">
                    Accidental <span class="text-pink">Damage</span>
                </h4>
                <p class="fs-small mb-0 text-white opacity-80">
                    Theft, Loss, Accidental Damage
                </p>
            </div>
            <h3 class="col-12 col-lg-2 fw-normal text-white mb-0 ms-md-auto p-2 text-md-end">R{{device['deal']['DealPrice']}}pm</h3>
            <!-- <a role="button" class="col-12 col-lg-1 text-decoration-none d-flex justify-content-end">
                <i (click)="removeDeviceFromDevices(device)" class="pi pi-times text-info opacity-62"></i>
            </a> -->
        </div>
        <div *ngIf="device['CategoryID'] == 'NewSmart'" class="d-flex flex-column flex-md-row align-items-center bg-blue rounded p-2 row mb-2">
            <h4 class="col-12 col-lg-4 fw-bold text-white mb-0 p-2">{{ device['Title'] }}</h4>
            <div class="col-12 col-lg-4 p-2 text-center text-md-start">
                <h4 class="fw-bold text-info mb-0">
                    Tech <span class="text-pink">Smart</span>
                </h4>
                <p class="fs-small mb-0 text-white opacity-80">
                    Theft, Loss, Accidental Damage
                </p>
            </div>
            <h3 class="col-12 col-lg-2 fw-normal text-white mb-0 ms-md-auto p-2 text-md-end">R{{device['deal']['DealPrice']}}pm</h3>
            <!-- <a role="button" class="col-12 col-lg-1 text-decoration-none d-flex justify-content-end">
                <i (click)="removeDeviceFromDevices(device)" class="pi pi-times text-info opacity-62"></i>
            </a> -->
        </div>
    </div>      
</div>
