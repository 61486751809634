import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'layout-page-account-dashboard',
  templateUrl: './layout-page-account-dashboard.component.html',
  styleUrls: ['./layout-page-account-dashboard.component.scss']
})
export class LayoutPageAccountDashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
