import { RendererFactory2 } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";
import { DialogService } from 'primeng/dynamicdialog';
import { AppService } from "src/app/app.service";
import { PipeAttributePipe } from "src/app/pipe/pipe-attribute.pipe";
import { LandingPageGql } from "../gql";
import { DomService } from "../service/dom.service";
export class LandingPageConfigureOption {
    public title?: Title;
    public meta?: Meta;
    public dialogService?: DialogService;
    public environment?: any;
    public landingPageGql?: LandingPageGql;
    public attributePipe?: PipeAttributePipe;
    public domService?: DomService;
    public appService?:AppService;
}