import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[image-src]'
})
export class DirectiveImageSrcDirective implements OnInit, AfterViewInit {

  private _srcset: string | undefined;
  private _src: SafeResourceUrl | undefined;

  @Input("image-src") element: any;

  constructor(
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    console.debug("DirectiveImageSrcDirective ngOnInit host ", this._elementRef.nativeElement, " element ", this.element);
    console.debug("DirectiveImageSrcDirective element ", this.element);

    if (_.isArrayLikeObject(this.element)) {
      const images: string[] = _.map(this.element, (item) => {
        const url = _.get(item, "attributes.url");
        console.debug("URL:", url); // Added this line for debugging
        return _.get(item, "attributes.url");
      });
      console.debug("DirectiveImageSrcDirective maybe a src set ", _.size(images), " attributes ", images);

      if (_.size(images) > 1) {
        this.src = this._sanitizer.bypassSecurityTrustResourceUrl(_.first(images)!);
        this.srcset = images.join(", ") + " 2x";
        console.debug("DirectiveImageSrcDirective ", " this.src  ", this.src, " this.srcset ", this.srcset);
        this._renderer.setProperty(this._elementRef.nativeElement, 'src', this.src);
        this._renderer.setProperty(this._elementRef.nativeElement, 'srcset', this.srcset);
      } else if (_.size(images) === 1) {
        this.src = this._sanitizer.bypassSecurityTrustResourceUrl(_.first(images)!);
        console.debug("DirectiveImageSrcDirective ", " this.src  ", this.src);
        this._renderer.setProperty(this._elementRef.nativeElement, 'src', this.src);
      }
    } else {
      console.debug("DirectiveImageSrcDirective not array type ", this.element);
      this.src = this._sanitizer.bypassSecurityTrustResourceUrl(_.get(this.element, "Items.data[0].attributes.url")!);
      this._renderer.setProperty(this._elementRef.nativeElement, 'src', this.src);
    }
  }

  ngAfterViewInit(): void {}

  get src(): SafeResourceUrl | undefined {
    return this._src;
  }

  set src(src: SafeResourceUrl | undefined) {
    this._src = src;
  }

  get srcset(): string | undefined {
    return this._srcset;
  }

  set srcset(srcset: string | undefined) {
    this._srcset = srcset;
  }
}
