import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ArticleGql, Widget } from 'ngx-techsured';

@Component({
  selector: 'widget-article-carousel',
  templateUrl: './widget-article-carousel.component.html',
  styleUrls: ['./widget-article-carousel.component.scss']
})
export class WidgetArticleCarouselComponent extends Widget implements OnInit {

  @Input() categorySlug: string | undefined;
  @Input() display:"Card"|"Button" = "Card";

  articles: any[] | undefined;
  responsiveOptions = [
    {
      breakpoint: '1200px',
      numVisible: 2,
      numScroll: 1,
    },
    {
      breakpoint: '768px',
      numVisible: 1,
      numScroll: 1,
    }
  ];

  constructor(
    private readonly _articleGql: ArticleGql
  ) {
    super();
  }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: '1200px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1,
      }
    ];
    this.getArticles(this.categorySlug);
    console.debug("WidgetArticleCarouselComponent display ", this.display, " this.categorySlug ", this.categorySlug);
  }


  getArticles(categorySlug?: string) {
    if (categorySlug) {

      this._articleGql.findByCategorySlug(categorySlug).subscribe({
        next: (response) => {
          this.busy = response.loading;
          if (!this.busy) {
            console.debug("response ", response);
            console.debug("this.categorySlug ", this.categorySlug, " articles ", _.get(response, "data.articles.data"))
            const mappedArticles: any[] = _.map(_.get(response, "data.articles.data"), (item) => {
              console.debug("mapping ", item)
              return {

                thumbnail: _.get(item, "attributes.Thumbnail.data"),
                cardImageAlt: _.get(item, "attributes.Title"),
                cardHeading: _.get(item, "attributes.Title"),
                tag: _.get(item, "attributes.Categories.data[0].attributes.Name"),
                author: `${_.get(item, "attributes.Author.data.attributes.firstName")} ${_.get(item, "attributes.Author.data.attributes.lastName")}`,
                authorPhoto: _.get(item, "attributes.Author.data.attributes.avatar.data.attributes.url"),
                url: `/resources/article/${_.get( item, "attributes.Slug")}`,
                createdAt:_.get(item, "attributes.createdAt"),
                estimatedReadTime:_.get(item, "attributes.EstimatedReadTime"),
                estimatedReadTimeMetric:`${ _.camelCase(_.get(item, "attributes.EstimatedReadTimeMetric")) } read`.toLowerCase(),

              };
            });
            

            this.articles = mappedArticles;
            console.debug(" mappedArticles ", this.articles);
          }
        },
        error: (error: any) => {
          console.error("Unable to obtain articles ", error);
        },
        complete: () => {
          this.busy = false;
        }
      })

    } else {

      this._articleGql.findAllArticles().subscribe({
        next: (response) => {
          this.busy = response.loading;
          if (!this.busy) {
            console.debug("response ", response);
            console.debug("articles ", _.get(response, "data.articles.data"))
            const mappedArticles: any[] = _.map(_.get(response, "data.articles.data"), (item) => {
              console.debug("mapping ", item)
              return {

                thumbnail: _.get(item, "attributes.Thumbnail.data"),
                cardImageAlt: _.get(item, "attributes.Title"),
                cardHeading: _.get(item, "attributes.Title"),
                tag: _.get(item, "attributes.Categories.data[0].attributes.Name"),
                author: `${_.get(item, "attributes.Author.data.attributes.firstName")} ${_.get(item, "attributes.Author.data.attributes.lastName")}`,
                authorPhoto: _.get(item, "attributes.Author.data.attributes.avatar.data.attributes.url"),
                url: `/resources/article/${_.get( item, "attributes.Slug")}`,
                createdAt:_.get(item, "attributes.createdAt"),
                estimatedReadTime:_.get(item, "attributes.EstimatedReadTime"),
                estimatedReadTimeMetric:`${ _.camelCase(_.get(item, "attributes.EstimatedReadTimeMetric")) } read`.toLowerCase(),


              };
            });
            console.debug(" mappedArticles ", mappedArticles);

            this.articles = mappedArticles;
          }
        },
        error: (error: any) => {
          console.error("Unable to obtain articles ", error);
        },
        complete: () => {
          this.busy = false;
        }
      })

    }
  }
}
