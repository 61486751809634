import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'ngx-techsured';

@Component({
  selector: 'layout-page-public',
  templateUrl: './layout-page-public.component.html',
  styleUrls: ['./layout-page-public.component.scss']
})
export class LayoutPagePublicComponent implements OnInit {

  constructor(public loadingService:LoadingService) { }

  ngOnInit(): void {
  }

}
