import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'attribute'
})
export class PipeAttributePipe implements PipeTransform {

  transform(value: any, key:string): any {
    console.debug("finding ", key, " in ", value);
    return _.get( value, key );
  }

}
