import { ComponentRef, Type } from "@angular/core";
import * as _ from "lodash";
import { DynamicDialogComponent, DynamicDialogConfig, DynamicDialogRef } from "primeng/dynamicdialog";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { PageConfigureOption } from "./page-configuration-option";
import { ApolloQueryResult } from "@apollo/client/core";

export class Page {
  busy: boolean = false;
  data: any;
  sections: any[] | undefined;
  elements: any[] | undefined;
  responsiveOptions: { breakpoint: string; numVisible: number; numScroll: number }[] = [];

  constructor(protected pageConfigureOption?: PageConfigureOption) {
    this.responsiveOptions = [
      { breakpoint: '1200px', numVisible: 2, numScroll: 1 },
      { breakpoint: '768px', numVisible: 1, numScroll: 1 }
    ];
  }

  protected init(data: any, initSeo: boolean = true) {
    this.data = data;
    this.elements = this.getElements();
    console.debug("page elements init", this.elements);
    if (initSeo) {
      this.initSEO(data);
    }
  }

  set title(title: string) {
    if (this.pageConfigureOption?.title) {
      this.pageConfigureOption.title.setTitle(title);
    }
  }

  protected initSEO(subject?: any) {
    const strapiSeo = subject ? subject : this.getElemetByType("ComponentSharedSeo") || {};
    const routeFallback = (this as any).fallbackSeo || {};
    const seoConfiguration = { ...strapiSeo, ...routeFallback };
    const seoSocial: any = this.initSEOGetSocialAttribute(_.get(seoConfiguration, "metaSocial"));
    const seoStructuredData: any = _.get(seoConfiguration, "structuredData");

    console.debug("seo init final seoConfiguration", seoConfiguration);
    console.debug("seo init social", seoSocial);
    console.debug("seo init structured data", seoStructuredData);

    this.title = _.get(seoConfiguration, "metaTitle");

    const seo: any = _.omitBy(
      {
        title: _.get(seoConfiguration, "metaTitle"),
        description: _.get(seoConfiguration, "metaDescription"),
        keywords: _.get(seoConfiguration, "keywords"),
        robots: _.get(seoConfiguration, "metaRobot"),
        viewport: _.get(seoConfiguration, "metaViewPort"),
        ...seoSocial
      },
      _.isNil
    );

    console.debug("seo init final meta object", seo);

    const metaService = this.pageConfigureOption?.meta;
    if (metaService) {
      Object.keys(seo).forEach((key) => {
        if (metaService.getTag(`name="${key}"`)) {
          metaService.updateTag({ name: key, content: seo[key] });
        } else {
          metaService.addTag({ name: key, content: seo[key] });
        }
      });
    }

    if (
      seoStructuredData &&
      this.pageConfigureOption?.appService?.isBrowser &&
      this.pageConfigureOption?.domService
    ) {
      const attached: any = this.pageConfigureOption.domService.addScript(
        "seo",
        undefined,
        JSON.stringify(seoStructuredData),
        undefined,
        "application/ld+json",
        "head"
      );
      console.debug("seo adding structured data", attached);
    }
  }

  private initSEOGetSocialAttribute(metaSocial: any[]): any {
    let social: any = {};
    if (metaSocial) {
      console.debug("seo init initSEOGetSocialAttribute", metaSocial);
      metaSocial.forEach((value: any) => {
        social[_.toLower(`${value.socialNetwork}:description`)] = _.get(value, "description");
        social[_.toLower(`${value.socialNetwork}:title`)] = _.get(value, "title");
        social[_.toLower(`${value.socialNetwork}:image`)] = _.get(value, "image.data.attributes.url");
      });
    }
    console.debug("seo init metaSocial", social);
    return _.omitBy(social, _.isNil);
  }

  asset(url: string): string {
    return url;
  }

  assetSet(items: any[], setKey: string, ratio: string = "2x"): string | undefined {
    const set: string[] = items.map((item) => this.asset(_.get(item, setKey)));
    console.debug("assetSet items", items, "set", set);
    return _.isEmpty(set) ? undefined : `${set.join(", ")} ${ratio}`;
  }

  getContentByType(type: string) {
    console.debug("Page.getContentByType", type, this.data);
    return _.find(_.get(this.data, "content"), { type: type });
  }

  getElements() {
    return _.get(this.data, "pages.data.[0].attributes.Elements");
  }

  getElemetByType(type: string): any | undefined {
    if (this.elements) {
      const query: any = { __typename: type };
      console.debug("finding", query, "in", this.elements);
      return _.find(this.elements, query);
    }
    return undefined;
  }

  getElemetByTypeAndContentID(type: string, contentID: string): any | undefined {
    if (this.elements) {
      const query: any = { __typename: type, ContentID: contentID };
      console.debug("finding", query, "in", this.elements);
      return _.find(this.elements, query);
    }
    return undefined;
  }

  openModal(component: Type<any>, dynamicDialogConfig: DynamicDialogConfig): DynamicDialogRef | undefined {
    if (this.pageConfigureOption?.dialogService) {
      return this.pageConfigureOption.dialogService.open(component, dynamicDialogConfig);
    }
    return undefined;
  }

  closeModalAll() {
    if (this.pageConfigureOption?.dialogService) {
      this.pageConfigureOption.dialogService.dialogComponentRefMap.forEach(
        (componentRef: ComponentRef<DynamicDialogComponent>, dynamicDialogRef: DynamicDialogRef) => {
          dynamicDialogRef.close(null);
        }
      );
    }
  }

  getContentBySlug(slug: string): Observable<ApolloQueryResult<any>> | undefined {
    return this.pageConfigureOption?.pageGql?.findBySlugWithElements(slug);
  }

  attribute(context: any, path: string): any | undefined {
    if (this.pageConfigureOption?.attributePipe) {
      return this.pageConfigureOption.attributePipe.transform(context, path);
    }
    return undefined;
  }

  scrollToElement(target: Element | undefined) {
    console.debug("scrolling to", target);
    if (target) {
      target.scrollIntoView({ behavior: "smooth" });
    }
  }
}
