<div class="card bg-primary h-100">
    <div class="card-body p-4 mx-2 my-3 d-flex flex-column">
        <img src="{{item|attribute:'attributes.Images.data[0].attributes.url'}}" alt="Shield with tick mark"
            role="presentation" aria-hidden="true" width="41" height="50">
        <h3 class="fw-bold text-info mt-4 mb-0" emphasisLastWord="text-pink" [offset]="2">
            {{item|attribute:'attributes.Title'}}
        </h3>
        <h5 class="fw-bold mb-1 text-pink text-center">{{item|attribute: 'attributes.Subtitle'}}</h5>
        <h6 class="fw-normal text-white opacity-80">
            {{item|attribute:'attributes.Text'}}</h6>

        <ng-container *ngIf="features">
            <div class="col-12 container p-0">
                <ul class="list-unstyled row px-0">
                    <li *ngFor="let feature of features" class="list-item col-12 col-sm-12 fw-normal text-white opacity-80 mb-1">
                        {{feature.Text}}
                    </li>
                </ul>
            </div>
        </ng-container>
        <ng-container *ngIf="item|attribute:'attributes.Slug'">
            <div
                class="d-flex flex-column flex-sm-row flex-md-column flex-lg-row align-items-sm-center mt-auto gap-4 mt-4">
                <button pButton class="btn btn-sm px-5 btn-outline-secondary" label="Get Cover"
                    routerLink="/get-a-quote/{{item|attribute:'attributes.Slug'}}"></button>
                <button pButton class="btn fs-7 btn-link align-self-center" label="Learn More" (click)="route(item)">
                    <img src="/assets/icons/icon-check-right-thin.svg" alt="Arrow pointing right" role="presentation"
                        aria-hidden="true" width="10.56" height="22" class="order-1">
                </button>
            </div>
        </ng-container>
    </div>
</div>