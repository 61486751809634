
<h2 class="fs-2d fw-bold text-white text-center mx-auto my-3">My <span class="text-secondary">Deals</span></h2>

<div class="px-4">
    <div *ngFor="let deal of deals" class="d-flex flex-column flex-md-row align-items-center bg-blue rounded p-2">
        <h4 class="fw-bold text-white mb-0 p-2">{{ deal['cover_text'] }}</h4>
        <div class="me-md-5 ms-md-auto p-2 text-center text-md-start">
            <h4 class="fw-bold text-info mb-0">
                Accidental <span class="text-pink">Damage</span>
            </h4>
            <p class="fs-small mb-0 text-white opacity-80">
                Cover for multiple devices in your home
            </p>
        </div>
        <h3 class="fw-normal text-white mb-0 ms-md-auto me-md-4 p-2 text-md-end">{{deal['pm_amount_text']}}pm</h3>
        <!-- <a role="button" class="text-decoration-none align-self-end align-self-md-start order-first order-md-last">
            <i (click)="removeDeviceFromDevices(deal)" class="pi pi-times text-info opacity-62"></i>
        </a> -->
    </div>
</div>
<!-- <div class="d-flex flex-column flex-sm-row justify-content-sm-center align-items-sm-center px-4 px-sm-0 gap-3 pb-2 pt-4 mb-2">
    <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Update"
        (click)="close()">
    </button>
</div> -->