<!-- <p-dialog [(visible)]="displayPersonalEditModal" styleClass="modal-bg-dark modal-sm" maskStyleClass="mask-dark" [modal]="true" [draggable]="false" [resizable]="false">-->

<h2 class="fs-2d fw-bold text-white text-center mx-auto my-3">Edit <span class="text-secondary">Billing Details</span></h2>

<div class="px-4">
    <div class="row gx-3 gy-4">
        <div class="col-12">
            <p-dropdown [(ngModel)]="selectedBank" [options]="bankOptions" styleClass="w-100 text-start fs-7 icon-blue" placeholder="Bank" optionLabel="option"></p-dropdown>
        </div>
        <div class="col-12">
            <p-dropdown [(ngModel)]="selectedAccountType" [options]="accountOptions" styleClass="w-100 text-start fs-7 icon-blue" placeholder="Account Type" optionLabel="option"></p-dropdown>    
        </div>
        <!-- <div class="col-12">
            <input [(ngModel)]="billingInfo.BranchCode" type="text" class="w-100 fs-7" placeholder="Branch Code" pInputText value="" />
        </div> -->
        <div class="col-12">
            <input [(ngModel)]="billingInfo.AccountNumber" [class]="validFormControl('AccountNumber')" type="text" class="w-100 fs-7" placeholder="Account Number" pInputText value="" />
        </div>
        <div class="col-12">
            <p-dropdown [(ngModel)]="billingInfo.DebitDay" [options]="debitDays" styleClass="w-100 text-start fs-7 icon-blue" placeholder="Debit Day" optionLabel="option"></p-dropdown>
        </div>
    </div>
    <br><br><br>
</div>
<div class="d-flex flex-column flex-sm-row justify-content-sm-center align-items-sm-center px-4 px-sm-0 gap-3 pb-2 pt-4 mb-2">
    <div *ngIf="callResponseMsg.show" role="alert" class="alert alert-notice fw-bold text-red text-center">
        {{callResponseMsg.message}}
    </div>     
    <button type="button" pButton class="btn btn-sm btn-primary box-shadow px-5" label="Update"
        (click)="close()">
    </button>
</div>