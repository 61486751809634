<div class="row justify-content-center">
    <div class="col-12 col-md-6 col-xl-5 col-xxl-4 mb-3">
        <input [(ngModel)]="callMeData.FirstName" type="text" class="w-100" placeholder="First Name"
            aria-label="First Name" pInputText />
        <p *ngIf="validations.invalidFirstName" class="text-start text-danger">Please Provide Name</p>
    </div>
    <div class="col-12 col-md-6 col-xl-5 col-xxl-4 mb-3">
        <input [(ngModel)]="callMeData.Surname" type="text" class="w-100" placeholder="Surname" aria-label="Surname"
            pInputText />
        <p *ngIf="validations.invalidSurname" class="text-start text-danger">Please Provide Name</p>
    </div>
</div>
<div class="row justify-content-center">
    <div class="col-12 col-md-6 col-xl-5 col-xxl-4 mb-3">
        <input [(ngModel)]="callMeData.MobileNumber" type="tel" class="w-100" placeholder="Mobile Number"
            aria-label="Mobile Number" pInputText />
        <p *ngIf="validations.invalidMobileNumber" class="text-start text-danger">Please Provide Valid Mobile Number</p>
    </div>
    <div class="col-12 col-md-6 col-xl-5 col-xxl-4 mb-3">
        <input [(ngModel)]="callMeData.EmailAddress" type="email" class="w-100" placeholder="Email address"
            aria-label="Email address" pInputText />
        <!-- <p *ngIf="validations.invalidEmailAddress" class="text-start text-danger">Please Provide Valid Email</p> -->
    </div>
</div>

<div class="row justify-content-center mt-2">
    <div class="col-12 col-xl-10 col-xxl-8">
        <p-checkbox styleClass="ms-2" labelStyleClass="text-white text-start ms-3 lh-base" id="call-me-back-consent"
            value="true" name="call-me-back-consent"
            label="I consent to my personal information being used by Viva Cover to contact me and market insurance products that may be suitable to my needs. My information will be stored safely as per Viva Cover&apos;s privacy policy."
            [(ngModel)]="callMeBackConsent">
        </p-checkbox>
    </div>
</div>

<div class="row justify-content-center mt-4 pt-lg-3">
    <div class="col-12 col-md-6 col-lg-auto">
        <button [disabled]="!callMeBackConsent" (click)="onCallMeBackClick()" pButton class="btn btn-primary" label="Call me back"></button>
        <p *ngIf="showAgentWillContactYou" class="text-danger mt-2">An agent will be in contact shortly</p>
    </div>
</div>