<div class="article-highlight my-5">
  <h3 class="fs-3b fw-bold text-primary mb-4">
    {{Title}}
  </h3>
  <p class="text-primary">
    {{Summary}}
  </p>

  <ng-container *ngFor="let step of Steps; let index=index; let first=first;">
    <p class="text-primary">
      <span class="fw-bold">Step {{index+1}}:</span>
      {{step|attribute:'Text'}}
    </p>
    <ng-container *ngIf="step|attribute:'Image.data'">
      <component-image [element]="step|attribute:'Image.data'"></component-image>
    </ng-container>
  </ng-container>
</div>