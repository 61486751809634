import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-modal-billing-information',
    templateUrl: './modal-billing-information.component.html',
    styleUrls: ['./modal-billing-information.component.scss']
})
export class ModalBillingInformationComponent implements OnInit {

    callResponseMsg = {
        show: false,
        message: '',
    }

    bankOptions = [
        {option: 'ABSA BANK LIMITED', id: 3, 'default_code': '632005', 'code': '632005'},
        {option: 'BANK OF ATHENS', id: 5, 'default_code': '00000', 'code': '00000'},
        {option: 'BIDVEST BANK', id: 7, 'default_code': '00000', 'code': '00000'},
        {option: 'CAPITEC BANK', id: 8, 'default_code': '470010', 'code': '470010'},
        {option: 'FIRST NATIONAL BANK', id: 12, 'default_code': '254005', 'code': '254005'},
        {option: 'NEDBANK', id: 20, 'default_code': '198165', 'code': '198165'},
        {option: 'POSTBANK', id: 24, 'default_code': '00000', 'code': '00000'},
        {option: 'STANDARD BANK', id: 30, 'default_code': '051001', 'code': '051001'},
        {option: 'ITHALA', id: 16, 'default_code': '00000', 'code': '00000'},
        {option: 'INVESTEC BANK', id: 15, 'default_code': '00000', 'code': '00000'},
        {option: 'AFRICAN BANK', id: 4, 'default_code': '430000', 'code': '430000'}
    ];

    debitDays = [
        {option: '1st', id: 1},
        {option: '5th', id: 5},
        {option: '10th', id: 10},
        {option: '15th', id: 15},
        {option: '20th', id: 20},
        {option: '25th', id: 25},
        {option: '26th', id: 26},
        {option: '27th', id: 27},
        {option: '28th', id: 28},
        {option: '29th', id: 29},
        {option: '30th', id: 30},
        {option: '31st', id: 31},
    ];
    accountOptions = [
        {option: 'Savings', id: 1},
        {option: 'Current', id: 2},
    ];
    selectedBank: object = {};
    selectedAccountType: object = {};
    billingInfo: any = {
        'Bank': {},
        'AccountType': {},
        'AccountNumber': '',
        'BranchCode': '',
        'DebitDay': '',
    };

    formValidities: any = {
        'Bank': true,
        'AccountType': true,
        'AccountNumber': true,
        'BranchCode': true,
        'DebitDay': true,
    }

    isValidForm(){
        if(this.billingInfo['AccountNumber'].length < 8){
        this.callResponseMsg['show'] = true
        this.callResponseMsg['message'] = 'Please Provide A Minimum Of 8 Characters'    
        this.formValidities['AccountNumber']=false;
        return false
    }
        else{
        this.formValidities['AccountNumber']=true;
    }

        return true;
    }

    validFormControl(_key: any){
        const value = this.formValidities[_key]
        if(value){
            return 'form-control'
        }else{
            return 'form-control is-invalid'
        }
      }

    constructor(
        public dynamicDialogRef: DynamicDialogRef,
        public dynamicDialogConfig: DynamicDialogConfig,
        private _formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        let data = this.dynamicDialogConfig.data;
        console.log(data)
        this.billingInfo = data
        this.selectedAccountType = this.billingInfo.AccountType
        this.selectedBank = this.billingInfo.Bank
        if(this.billingInfo.DebitDay != ''){
            let obj = this.debitDays.find(x => x.id == this.billingInfo.DebitDay)
            this.billingInfo.DebitDay = obj
        }
    }

    close() {
        // dummy commit
        const valid = this.isValidForm()
        if(!valid){
            this.callResponseMsg['show'] = false
            return;
        }
    
        this.billingInfo.AccountType = this.selectedAccountType
        this.billingInfo.Bank = this.selectedBank
        this.billingInfo['DebitDay'] = this.billingInfo['DebitDay']['id']
        this.dynamicDialogRef.close(this.billingInfo);
    }
}
