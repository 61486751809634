import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[emphasisWord]'
})
export class EmphasisWordDirective implements OnInit, AfterViewInit {

  @Input("emphasisWord") emphasis: {
    word: string,
    style: "text-secondary" | "text-primary" | "text-pink"
  } | undefined;

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) { }

  ngOnInit(): void {
    console.debug("ngOnInit emphasisWord ", this._elementRef.nativeElement, " emphasis ", this.emphasis);
  }

  ngAfterViewInit(): void {
    const hostContent: string = this._elementRef.nativeElement.innerText;
    const emphasisColor: string = _.get(this.emphasis, "style")!; // Use non-null assertion operator
    const emphasisWord: string | undefined = _.get(this.emphasis, "word");

    console.debug("emphasisWordDirective host content ", hostContent);
    console.debug("emphasisWordDirective emphasis color ", emphasisColor);
    console.debug("emphasisWordDirective emphasis word ", emphasisWord);

    if (emphasisWord) {
      const newTitle: string = _.replace(hostContent, emphasisWord, `<span class="${emphasisColor}">${emphasisWord}</span>`);
      this._renderer.setProperty(this._elementRef.nativeElement, 'innerHTML', newTitle);
    }
  }
}
