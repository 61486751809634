export * from "./page/page.gql";
export * from "./landing-page/landing-page.gql";
export * from "./landing-page/query/landing-page-query.gql";
export * from "./page/query/page-query.gql";
export * from "./article/query/article-query.gql";
export * from "./article/article.gql";
export * from './article-category/query/article-category-query.gql';
export * from "./article/query/article-query.gql";
export * from "./frequently-asked-question/frequently-asked-question.gql";
export * from "./frequently-asked-question/query/frequently-asked-question-query.gql";
export * from "./product/product.gql";
export * from "./product/query/product-query.gql";
export * from "./testimony/query/testimony.qql";
export * from "./testimony/testimony.gql";
export * from "./downloadable/query/downloadable.query";
export * from "./downloadable/downloadable";