import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { ApiService } from '../core';
import { flatten } from 'flat';

const TIMEOUT = 20000
const DEBIT_ORDER_API_KEY = 'Basic dGVjaHN1cmVkZmlyc3R2aWV3OlQhZWNoMTVmdiM='
const OCP_APIM_SUBSCRIPTION_KEY = ''
@Injectable()
export class VasDealApiService extends ApiService {

  constructor(protected override readonly httpClient: HttpClient) {
    super(httpClient, "/api/vas");
  }
  
  findAllAvailableDeals(_provider: string, _flow_id: any): Observable<any> {
    const apiUrl = `https://vas.api.ignitiongroup.co.za/vas/${_provider}/GetAvailableDeals?flowId=${_flow_id}`;
    const base64encode = btoa("techsuredfirstview:T!ech15fv#");
    
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Basic ${base64encode}`
    });

    return this.httpClient.get(apiUrl, { headers }).pipe(
      tap((response) => {
        // This will not alter the response, just allows you to perform actions with it
      }),
      catchError((error) => {
        // Handle any errors here
        console.error('Error in findAllAvailableDeals:', error);
        throw error; // Rethrow or handle the error as appropriate
      })
    );
  }
}

