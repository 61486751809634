<ng-container *ngIf="items">
    <p-carousel [value]="items" [showIndicators]="false" [numVisible]="3" [numScroll]="1"
        [responsiveOptions]="responsiveOptions" styleClass="product-carousel">
        <ng-template let-item pTemplate="item">
            <div class="carousel-card-container mx-4 py-4">
                <div class="card border-0 rounded-0 rounded-bottom border-top border-secondary border-3">
                    <div class="card-body p-0">
                        <div class="d-flex flex-column align-items-center px-3 px-md-4 pt-4 gap-3">
                            <img src="{{item|attribute:'attributes.Images.data[0].attributes.url'}}"
                                alt="{{item|attribute:'attributes.Title'}}" height="60" width="60" role="presentation"
                                aria-hidden="true">
                            <h4 class="fw-bold text-dark mb-0 text-center">{{item|attribute:'attributes.Title'}}</h4>
                            <h5 class="fw-bold mb-4 text-pink text-center">{{item|attribute: 'attributes.Subtitle'}}</h5>
                            <ng-container *ngIf="showPrice">
                                <div class="d-flex align-items-end text-primary">
                                    <h6 class="fw-normal me-2">FROM </h6>
                                    <h2 class="fw-bold lh-xs">R{{item|attribute:'attributes.Price'}}</h2>
                                    <h6 class="fw-bold">pm</h6>
                                </div>
                            </ng-container>

                            <button pButton class="btn btn-sm bg-white btn-outline-primary w-100"
                                label="{{item|attribute:'attributes.Text'}}"
                                routerLink="/get-a-quote"></button>

                        </div>
                        <div class="card-footer-bg px-3 px-md-4 pb-4 text-center">
                            <!-- FIXME change link to link captured on CMS -->
                            <button pButton class="btn btn-link" label="Learn more"
                                [routerLink]="['/device-insurance', item|attribute:'attributes.Slug' ]">
                                <img src="assets/icons/icon-check-right-thin.svg" alt="Arrow pointing right"
                                    role="presentation" aria-hidden="true" width="7" height="14.59" class="order-1">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel>
</ng-container>