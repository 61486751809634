import { Directive, Input, ElementRef, Renderer2, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Directive({
  selector: '[image-src]'
})
export class SafeImageDirective implements OnInit {
  @Input() appSafeImage: string = '' // Input property to bind the dynamic image URL

  constructor(private el: ElementRef, private renderer: Renderer2, private sanitizer: DomSanitizer) { }

  ngOnInit() {
    if (this.appSafeImage) {
      const safeImageUrl: SafeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.appSafeImage);
      this.renderer.setAttribute(this.el.nativeElement, 'src', safeImageUrl.toString());
    }
  }
}