import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'component-product-grid',
  templateUrl: './component-product-grid.component.html',
  styleUrls: ['./component-product-grid.component.scss']
})
export class ComponentProductGridComponent implements OnInit {

  @Input() element:any;
  items:any[] | undefined;
  @Input() display:"grid"|"row" = "grid";

  constructor() { }

  ngOnInit(): void {
    console.debug("ComponentProductGridComponent ", this.element);
    this.items = _.get( this.element, "Products.data");
  }

}
