import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'component-hero',
  templateUrl: './component-hero.component.html',
  styleUrls: ['./component-hero.component.scss']
})
export class ComponentHeroComponent implements OnInit {

  @Input() Element: any | undefined;

  @Input() Title: string | undefined;
  @Input() Text: string | undefined;
  @Input() Button: any | undefined;
  @Input() BackgroundImageUrl: string | undefined;
  @Input() Icon: string | undefined;

  constructor(private _router: Router) { }

  ngOnInit(): void {
    if (this.Element) {
      this.Title = _.get(this.Element, "Title");
      this.Text = _.get(this.Element, "Text");
      this.Button = _.get(this.Element, "Button");

      const icon: any = _.get(this.Element, "Icon.data.attributes.url");
      if (icon) {
        this.Icon = _.get(this.Element, "Icon.data.attributes.url");
      }

      const background: any = _.get(this.Element, "BackgroundImage.data.attributes.url");
      if (background) {
        this.BackgroundImageUrl =_.get(this.Element, "BackgroundImage.data.attributes.url");
      }
    }
  }

  route(url: string) {
    console.debug("routing to ", url);
    this._router.navigateByUrl(url);
  }

}
