import { Component, OnInit } from '@angular/core';
import { EnergyLeadApiService } from 'ngx-techsured';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { FormPrepopulateService } from "src/app/service/form-prepop.services";

@Component({
  selector: 'component-call-me-back',
  templateUrl: './component-call-me-back.component.html',
  styleUrls: ['./component-call-me-back.component.scss']
})
export class ComponentCallMeBackComponent implements OnInit {

  constructor(
    private readonly _energyLeadApiService:EnergyLeadApiService, private formPrepopulateService: FormPrepopulateService
    ) { }

  decodedUd: any;

  callMeData: any = {
    'FirstName': '',
    'Surname': '',
    'MobileNumber': '',
    'EmailAddress': ''
  }

  ngOnInit(): void {
    
    this.decodedUd = this.formPrepopulateService.getDecodedUd();

    if (this.decodedUd) {
      console.log("This is the decodedud", this.decodedUd);
      let mobile = this.decodedUd.mobileNumber;
      if(mobile.startsWith('27')) {
          this.decodedUd.mobileNumber = '0' + mobile.substring(2);
      } else if (mobile.startsWith('+27')) {
          this.decodedUd.mobileNumber = '0' + mobile.substring(3);
      }
      this.callMeData.MobileNumber = mobile
      this.callMeData = {
          FirstName: this.decodedUd.firstName,
          Surname: this.decodedUd.lastName,
          EmailAddress: this.decodedUd.email,
          MobileNumber: this.decodedUd.mobileNumber,
          IdNumber: this.decodedUd.idNumber
      };
      console.log("This is call me data", this.callMeData)
  }
  }

  validations: any = {
    'invalidFirstName': false,
    'invalidSurname': false,
    'invalidMobileNumber': false,
    'invalidEmailAddress': false
  }

  showAgentWillContactYou: boolean = false
  callMeBackConsent: boolean = false

  isValidPayload(_payload: any){
    if(this.callMeData.FirstName.length < 1){this.validations.invalidFirstName=true;return false;}else{this.validations.invalidFirstName=false;}
    if(this.callMeData.Surname.length < 1){this.validations.invalidSurname=true;return false;}else{this.validations.invalidSurname=false;}
    if(this.callMeData.MobileNumber.length != 10){this.validations.invalidMobileNumber=true;return false;}else{this.validations.invalidMobileNumber=false;}
    // if(this.callMeData.EmailAddress.length < 10){this.validations.invalidEmailAddress=true;return false;}else{this.validations.invalidEmailAddress=false;}
    return true;
  }

  onCallMeBackClick(){
  let payload = this.callMeData
  if(!this.isValidPayload(payload)){return;};
  try {
    this.showAgentWillContactYou = true
      this._energyLeadApiService.callMeBack(payload).subscribe({
          next:(_response)=>{
              console.log(_response.newDeviceData)
              setTimeout(() => {
                this.showAgentWillContactYou = false
                this.callMeBackConsent = false
              }, 2000)
          },
          error:(error:any)=>{
              console.error("failed to make call me back request ", error);
              this.showAgentWillContactYou = false
          }
      });
  } catch (error) { }
  }
}