import { AfterViewInit, Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import * as _ from 'lodash';

@Directive({
  selector: '[header]'
})
export class HeaderDirective implements OnInit, AfterViewInit {

  @Input('header') element: any | undefined;

  colorClass: any = {
    Orange: "text-secondary",
    Blue: "text-primary",
    Pink: "text-pink",
    Intellicell: "text-info",
    Best4u: "text-warning"
  };

  constructor(private _elementRef: ElementRef, private _renderer: Renderer2) { }

  ngOnInit(): void {
    console.debug("HeaderDirective ngOnInit host ", this._elementRef.nativeElement, " element ", this.element);
  }

  ngAfterViewInit(): void {
    this._renderer.setProperty(this._elementRef.nativeElement, 'innerText', _.get(this.element, "Title"));
    const hostContent: string = this._elementRef.nativeElement.innerText;
    const emphasisColor: string = _.get(this.element, "EmphasisColor", "Orange");
    const emphasisWord: string | undefined = _.get(this.element, "EmphasisWord");
    const title: string = _.get(this.element, "Title");

    console.debug("headHeaderDirective host content ", hostContent);
    console.debug("headHeaderDirective emphasis color ", emphasisColor);
    console.debug("headHeaderDirective emphasis word ", emphasisWord);
    console.debug("headHeaderDirective title ", title);

    if (emphasisWord) {
      const newTitle: string = _.replace(title, emphasisWord, `<span class="${this.colorClass[emphasisColor || "ORANGE"]}">${emphasisWord}</span>`);
      this._renderer.setProperty(this._elementRef.nativeElement, 'innerHTML', newTitle);
    } else {
      this._renderer.setProperty(this._elementRef.nativeElement, 'innerHTML', title);
    }
  }
}
