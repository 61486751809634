import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-modal-devices-list',
    templateUrl: './modal-devices-list.component.html',
    styleUrls: ['./modal-devices-list.component.scss']
})
export class ModalDevicesListComponent implements OnInit {
    
    devices: any = []

    constructor(
        public dynamicDialogRef: DynamicDialogRef,
        public dynamicDialogConfig: DynamicDialogConfig,
        private _formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        let data = this.dynamicDialogConfig.data;
        // console.log(data)
        this.devices = data
    }

    close() { 
        this.dynamicDialogRef.close({});
    }
}
