<main class="bg-primary p-4 min-vh-100 d-flex flex-column align-items-center text-center">

    <a role="button" (click)="onCallMeBackClick()" class="text-white fs-7 d-inline-flex text-decoration-none align-items-center ms-lg-auto">
        Need help? <span class="ms-2 fw-bold">Call me back</span> <i class="pi pi-fw pi-angle-right fs-6"></i>
    </a>

    <a role="link" [routerLink]="['/']" class="mt-4 mt-lg-0">
        <img srcset="/assets/techsured_logo.png, /assets/techsured_logo@2x.png 2x" src="/assets/techsured_logo.png" alt="Techsured Logo" width="110" height="114">
    </a>

    <ng-content></ng-content>

    <footer class="bg-primary mt-auto w-100">
        <div class="d-flex flex-wrap justify-content-center justify-content-sm-start align-items-center gap-3 gap-sm-4">
            <span class="fs-x-small text-white opacity-62 me-sm-auto order-last order-sm-first">Copyright Techsured 2022</span>
                
            <a *ngIf="downloadables|find:{'identifier':'pdf_privacy_policy'}" target="_blank" role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" 
            [href]="downloadables|find:{'identifier':'pdf_privacy_policy'}|attribute:'url'">Privacy Policy</a>

                <a *ngIf="downloadables|find:{'identifier':'pdf_conflict_of_interest'}" target="_blank" role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" 
                [href]="downloadables|find:{'identifier':'pdf_terms_conditions'}|attribute:'url'">Terms & Conditions</a>
                
                <a *ngIf="downloadables|find:{'identifier':'pdf_terms_conditions'}" target="_blank" role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" 
                [href]="downloadables|find:{'identifier':'pdf_paia_manual'}|attribute:'url'">PAIA Manual</a>

                
                <a *ngIf="downloadables|find:{'identifier':'pdf_disclosure_notice'}" target="_blank" role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" 
                [href]="downloadables|find:{'identifier':'pdf_disclosure_notice'}|attribute:'url'">Disclosure Notice</a>
        </div>
    </footer>
</main>