import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'component-inline-alert',
  templateUrl: './component-inline-alert.component.html',
  styleUrls: ['./component-inline-alert.component.scss']
})
export class ComponentInlineAlertComponent implements OnInit {

  @Input() element: any | undefined;
  constructor() { }

  ngOnInit(): void {
    console.debug("element ", this.element);
  }

}
