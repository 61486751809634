import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'component-icon-group',
  templateUrl: './component-icon-group.component.html',
  styleUrls: ['./component-icon-group.component.scss']
})
export class ComponentIconGroupComponent implements OnInit {

  @Input() icons: Icon[] | undefined;
  @Input() element: any | undefined;
  @Input() textStyle: any | undefined;
  @Input() dashedSeperated: boolean = true;
  constructor() { }

  ngOnInit(): void {
    if (this.icons && this.element) {
      console.debug("ComponentIconGroupComponent icons ", this.icons, " element ", this.element);
      console.error("Please set icons or element not both");
    }
    else if (this.element) {
      this.icons = _.get(this.element, "Icons");
      console.debug("")
    }

    console.debug("ComponentIconGroupComponent icons ", this.icons);
  }
}


//FIXME: Generate models from openapi swagger.
class Icon {
  __typename: string | undefined;
  Identifier: string | undefined;
  Title: string | undefined;
  Text: string | undefined;
  Icon: string | undefined;
}
