<ng-container *ngIf="display==='grid'">
    <div class="row row-cols-1 row-cols-md-2 g-4 g-xl-5">
        <div class="col" *ngFor="let item of items ">
            <component-product-card [item]="item"></component-product-card>
        </div>
    </div>
</ng-container>
<ng-container *ngIf="display==='row'">
    <div class="row">
        <div class="col-lg-4 col-md-12 mt-2" *ngFor="let item of items ">
            <component-product-card [item]="item"></component-product-card>
        </div>
    </div>
</ng-container>