import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-modal-home-deals',
    templateUrl: './modal-home-deals.component.html',
    styleUrls: ['./modal-home-deals.component.scss']
})
export class ModalHomeDealsComponent implements OnInit {
    
    deals: any = []

    constructor(
        public dynamicDialogRef: DynamicDialogRef,
        public dynamicDialogConfig: DynamicDialogConfig,
        private _formBuilder: FormBuilder
    ) { }

    ngOnInit(): void {
        let data = this.dynamicDialogConfig.data;
        console.log(data)
        this.deals = data
    }

    close() { 
        this.dynamicDialogRef.close({});
    }
}
