<!-- Navbar -->
<nav class="fixed-top">
    <section class="navbar-strip text-center">
        <div class="d-inline-flex justify-content-center align-items-center">
            <!-- <a>
                <span class="fw-bold">Get our new Techsured Plus! Learn more</span>
                <i class="pi pi-arrow-right"></i>
            </a> -->
        </div>
    </section>
    <section class="navbar">
        <a role="link" routerLink="/" class="navbar-brand">
            <img srcset="/assets/techsured_logo.png, /assets/techsured_logo@2x.png 2x" src="/assets/techsured_logo.png"
                alt="Techsured Logo" width="110" height="114">
        </a>

        <!-- Mobile nav toggle button -->
        <button type="button" class="d-lg-none ms-auto w-auto bg-transparent rounded-0 border-0 p-2 align-self-center"
            pButton icon="pi fs-2 pi-bars" (click)="toggleNav()" aria-label="Show the Menu"
            *ngIf="!showMobileNav"></button>
        <button type="button" class="d-lg-none ms-auto w-auto bg-transparent rounded-0 border-0 p-2 align-self-center"
            pButton icon="pi fs-2 pi-times" (click)="toggleNav()" aria-label="Hide the Menu"
            *ngIf="showMobileNav"></button>

        <!-- Desktop navigation -->
        <div class="navbar-nav">
            <a #deviceInsurance role="button" class="nav-item" (click)="nav_dropdown.toggle($event)"
                [ngClass]="{'active': activeMenuItem }">
                Device Insurance <i class="ms-1 pi pi-angle-down"></i>
                <p-overlayPanel #nav_dropdown [appendTo]="deviceInsurance" styleClass="navbar-dropdown"
                    (onShow)="activeMenuItem = true" (onHide)="activeMenuItem = false">
                    <ng-template pTemplate>
                        <div class="red-line"></div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6 d-flex flex-column gap-3">
                                <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                    routerLink="/device-insurance/cellphone-insurance">
                                    <img src="/assets/icons/icon-cellphone.svg" alt="Cellphone icon" role="presentation"
                                        aria-hidden="true" width="38" height="38">
                                    <span class="text-dark fw-bold">Cellphone Insurance</span>
                                </a>
                                 <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                    routerLink="/device-insurance/decoder-insurance">
                                    <img src="/assets/icons/icon-decoder.svg" alt="Decoder icon" role="presentation"
                                        aria-hidden="true" width="38" height="38">
                                    <span class="text-dark fw-bold">Decoder Insurance</span>
                                </a>
                                <a role="button"
                                class="d-flex align-items-center gap-2 text-decoration-none"
                                routerLink="/device-insurance/home-device-insurance">
                                <img src="/assets/icons/icon-home-device.svg" alt="Shield with home icon"
                                    role="presentation" aria-hidden="true" width="36" height="36">
                                    <span class="text-dark fw-bold" style="font-size: 15px;">Home Device Insurance</span>
                            </a>
                                <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                    routerLink="/device-insurance/laptop-insurance">
                                    <img src="/assets/icons/icon-laptop.svg" alt="Laptop icon" role="presentation"
                                        aria-hidden="true" width="38" height="38">
                                    <span class="text-dark fw-bold">Laptop Insurance</span>
                                </a>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6 d-flex flex-column gap-3">
                                <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                routerLink="/device-insurance/tablet-insurance">
                                <img src="/assets/icons/icon-tablet.svg" alt="Tablet icon" role="presentation"
                                    aria-hidden="true" width="38" height="38">
                                <span class="text-dark fw-bold">Tablet Insurance</span>
                            </a>
                            <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                            routerLink="/device-insurance/screen-protect">
                            <img src="/assets/icons/icon-cellphone.svg" alt="Cellphone icon" role="presentation"
                                aria-hidden="true" width="38" height="38">
                            <span class="text-dark fw-bold">Screen Protect</span>
                        </a>
                            <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                routerLink="/device-insurance/wearable-insurance">
                                <img src="/assets/icons/icon-wearable.svg" alt="Wearable icon" role="presentation"
                                    aria-hidden="true" width="38" height="38">
                                <span class="text-dark fw-bold">Wearable Insurance</span>
                            </a>
                            </div>
                        </div>

                    </ng-template>
                </p-overlayPanel>
            </a>
            <a role="button" class="nav-item" [routerLink]="['/']" fragment="how-it-works"
                id="navigationItemHowItWorks">How It
                Works</a>
            <a role="button" class="nav-item" routerLink="/about-us">About Us</a>
            <a role="button" class="nav-item" routerLink="/resources">Resources</a>
            <a role="button" class="nav-item" routerLink="/contact-us">Contact Us</a>
        </div>

        <div class="ms-auto d-none d-lg-flex align-items-center gap-3">
            <!-- <button pButton class="btn btn-sm btn-outline-white fw-normal" label="Login" routerLink="/account/login"></button> -->
            <button pButton class="btn btn-sm btn-primary" label="Get Quote" routerLink="/get-a-quote"></button>
        </div>
    </section>
</nav>

<!-- Mobile navigation -->
<p-accordion styleClass="d-lg-none mobile-nav">
    <p-accordionTab [(selected)]="showMobileNav">
        <nav class="nav bg-white">
            <div class="d-flex flex-column">
                <a #deviceInsurance role="button" class="nav-item" (click)="activeMenuItem = !activeMenuItem"
                    [ngClass]="{'active': activeMenuItem }">
                    Device Insurance <i class="ms-1 pi pi-angle-down"></i>
                </a>
                <p-accordion styleClass="mobile-nav-dropdown">
                    <p-accordionTab [(selected)]="activeMenuItem">
                        <div class="d-flex flex-column gap-3 pt-3 pb-4">
                            <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                routerLink="/device-insurance/cellphone-insurance">
                                <img src="/assets/icons/icon-cellphone.svg" alt="Cellphone icon" role="presentation"
                                    aria-hidden="true" width="38" height="38">
                                <span class="text-dark fw-bold">Cellphone Insurance</span>
                            </a>
                            <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                            routerLink="/device-insurance/decoder-insurance">
                            <img src="/assets/icons/icon-decoder.svg" alt="Decoder icon" role="presentation"
                                aria-hidden="true" width="38" height="38">
                            <span class="text-dark fw-bold">Decoder Insurance</span>
                        </a>
                        <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                routerLink="/device-insurance/home-device-insurance">
                                <img src="/assets/icons/icon-home-device.svg" alt="Home device icon" role="presentation"
                                    aria-hidden="true" width="38" height="38">
                                <span class="text-dark fw-bold">Home Device Insurance</span>
                            </a>
                            <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                routerLink="/device-insurance/laptop-insurance">
                                <img src="/assets/icons/icon-laptop.svg" alt="Laptop icon" role="presentation"
                                    aria-hidden="true" width="38" height="38">
                                <span class="text-dark fw-bold">Laptop Insurance</span>
                            </a>
                            <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                routerLink="/device-insurance/screen-protect">
                                <img src="/assets/icons/icon-screen-protect.svg" alt="Screen Protect icon" role="presentation"
                                    aria-hidden="true" width="38" height="38">
                                <span class="text-dark fw-bold">Screen Protect</span>
                            </a>
                            <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                routerLink="/device-insurance/tablet-insurance">
                                <img src="/assets/icons/icon-tablet.svg" alt="Tablet icon" role="presentation"
                                    aria-hidden="true" width="38" height="38">
                                <span class="text-dark fw-bold">Tablet Insurance</span>
                            </a>
                            <a role="button" class="d-flex align-items-center gap-2 text-decoration-none"
                                routerLink="/device-insurance/wearable-insurance">
                                <img src="/assets/icons/icon-wearable.svg" alt="Wearable icon" role="presentation"
                                    aria-hidden="true" width="38" height="38">
                                <span class="text-dark fw-bold">Wearable Insurance</span>
                            </a>
                      </div>
                    </p-accordionTab>
                </p-accordion>

                <a role="button" class="nav-item" [routerLink]="['/']" fragment="how-it-works">How It Works</a>
                <a role="button" class="nav-item" routerLink="/about-us">About Us</a>
                <a role="button" class="nav-item" routerLink="/resources">Resources</a>
                <a role="button" class="nav-item" routerLink="/contact-us">Contact Us</a>

                <div class="d-flex flex-column my-4 gap-3">
                    <!-- <button pButton class="btn btn-outline-secondary fw-normal" label="Login"
                        routerLink="/account/login"></button> -->
                    <button pButton class="btn btn-primary" label="Get Quote" routerLink="/get-a-quote"></button>
                </div>
            </div>
        </nav>
    </p-accordionTab>
</p-accordion>