import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'component-paragraph-group',
  templateUrl: './component-paragraph-group.component.html',
  styleUrls: ['./component-paragraph-group.component.scss']
})
export class ComponentParagraphGroupComponent implements OnInit {

  @Input() element: any | undefined;
  Paragraphs: any[] | undefined;

  constructor() { }

  ngOnInit(): void {
    console.debug("element ", this.element);
    this.Paragraphs = _.get(this.element, "Paragraphs");
  }

}
