import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { TestimonyGql } from 'ngx-techsured';

@Component({
  selector: 'widget-testimony-carousel',
  templateUrl: './widget-testimony-carousel.component.html',
  styleUrls: ['./widget-testimony-carousel.component.scss']
})
export class WidgetTestimonyCarouselComponent implements OnInit {

  @Input() Element: any;
  items: any[] | undefined;

  constructor(private readonly _testimonyGql: TestimonyGql) { }

  ngOnInit(): void {

    this._testimonyGql.findAll().subscribe({
      next: ({ loading, data }) => {
        if( !loading ){
          const results:any = _.get( data,"testimonies.data" );
          console.debug("testimonies ", results);
          this.items = _.map( results, (value)=>{
            console.debug("testimonies mapping ", value);
            return       {
              stars: 4,
              text: _.get( value, "attributes.Text" ),
              fullName: _.get( value, "attributes.CustomerFullName" ),
              jobTitle: _.get( value, "attributes.CustomerJobTitle" ),
              photo: _.get( value, "attributes.CustomerAvatar.data.attributes.url" ),
              companyLogo: _.get( value, "attributes.CompanyLogo.data.attributes.url" )
            }
          })
        }
      }
    })
  }

}
