import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'component-header',
  templateUrl: './component-header.component.html',
  styleUrls: ['./component-header.component.scss']
})
export class ComponentHeaderComponent implements OnInit {

  @Input() element:any;
  @Input() styleClass:string = "fs-2c fw-bold text-dark";
  @Input() headerElement:string = "h3";
  
  Title:string | undefined;

  constructor() { }

  ngOnInit(): void {
    this.Title = _.get(this.element,"Title");
  }

}
