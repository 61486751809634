<ng-container *ngIf="items">
    <p-carousel [value]="items" [numVisible]="1" [numScroll]="1" indicatorStyleClass="mt-4">
        <ng-template let-item pTemplate="item">
            <div class="row justify-content-center">
                <div class="col-12 col-xl-10 col-xxl-9">
                    <div class="d-flex flex-column align-items-center text-center gap-4 px-4 px-xl-5">
                        <h5 class="fw-normal mt-4 mb-0 lh-base">{{item.text}}</h5>
                        <div class="d-flex align-items-center gap-2">
                            <p-avatarGroup>
                                <p-avatar image="{{item.photo}}" size="xlarge" shape="circle" *ngIf="item.photo"></p-avatar>
                                <p-avatar image="{{item.companyLogo}}" styleClass="logo-image" shape="circle" *ngIf="item.companyLogo">
                                </p-avatar>
                            </p-avatarGroup>
                            <div class="mb-1">
                                <span class="d-block fw-bold"  *ngIf="item.fullName">{{item.fullName}}</span>
                                <span class="d-block fs-xx-small ms-1"  *ngIf="item.jobTitle">{{item.jobTitle}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>
    </p-carousel>
</ng-container>