<ng-container *ngIf="frequentlyAskedQuestions">
    <p-accordion collapseIcon="pi pi-minus" expandIcon="pi pi-plus" styleClass="mt-4 mt-lg-5">
        <p-accordionTab *ngFor="let frequentlyAskedQuestion of frequentlyAskedQuestions; 
    let index=index; let first=first; let last=last;" header="{{frequentlyAskedQuestion|attribute:'attributes.Title'}}">
            
            <p>{{frequentlyAskedQuestion|attribute:'attributes.Text'}}</p>

            <ng-container *ngFor="let element of frequentlyAskedQuestion|attribute:'attributes.Elements'">
                <ng-container [ngSwitch]="element|attribute:'__typename'">
                    <ng-container *ngSwitchCase="'ComponentElementParagraph'">
                        <p class="text-dark">{{element|attribute:'Text'}}</p>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ComponentElementStepGroup'">
                        <component-step-group [element]="element"></component-step-group>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ComponentElementInlineAlert'">
                        <component-inline-alert [element]="element"></component-inline-alert>
                    </ng-container>
                    <ng-container *ngSwitchCase="'ComponentElementList'">
                        <component-list [element]="element" headerStyle="text-dark fw-normal mb-2 p"></component-list>
                    </ng-container>
                </ng-container>
            </ng-container>            

        </p-accordionTab>
    </p-accordion>
</ng-container>