<div class="bg-light rounded-3 p-4 mt-5">
  <div class="d-flex flex-column flex-sm-row gap-3 m-sm-2">
    <p-avatar styleClass="p-avatar-xxl" size="xlarge" shape="circle">
      <img alt="{{data|attribute:'data.attributes.avatar.data.attributes.url'}} profile image" src="{{data|attribute:'data.attributes.avatar.data.attributes.url'}}" />
    </p-avatar>
    <div class="mt-sm-2">
      <p class="mb-0 lh-1">More about the Author</p>
      <h3 class="fs-3b text-dark fw-bold">{{data|attribute:'data.attributes.firstName'}}
        {{data|attribute:'data.attributes.lastName'}}</h3>

      <div class="d-flex align-items-center gap-3 my-3">
        <ng-container *ngIf="data|attribute:'data.attributes.instagram'">
          <a href="https://instagram.com/{{data|attribute:'data.attributes.instagram'}}" class="text-decoration-none text-dark"><i class="pi pi-instagram fs-4"></i></a>
        </ng-container>
        <ng-container *ngIf="data|attribute:'data.attributes.facebook'">
          <a href="https://facebook.com/{{data|attribute:'data.attributes.facebook'}}" class="text-decoration-none text-dark"><i class="pi pi-facebook fs-4"></i></a>
        </ng-container>
        <ng-container *ngIf="data|attribute:'data.attributes.linkedin'">
          <a href="https://linkedin.com/{{data|attribute:'data.attributes.linkedin'}}" class="text-decoration-none text-dark"><i class="pi pi-linkedin fs-4"></i></a>
        </ng-container>
        <ng-container *ngIf="data|attribute:'data.attributes.email'">
          <a href="mailto:{{data|attribute:'data.attributes.email'}}" class="text-decoration-none text-dark"><i class="pi pi-envelope fs-4"></i></a>
        </ng-container>
      </div>

      <p class="text-dark">{{data|attribute:'data.attributes.bio'}}</p>
    </div>
  </div>
</div>