import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { ProductGql, Widget } from 'ngx-techsured';

@Component({
  selector: 'widget-product',
  templateUrl: './widget-product.component.html',
  styleUrls: ['./widget-product.component.scss']
})
export class WidgetProductComponent extends Widget implements OnInit {

  @Input() categorySlug:string |undefined;

  items: {
    icon: string;
    name: string;
    price: number;
    ctaText: string;
    routerLink: string;
  }[]|undefined;

  responsiveOptions: {
    breakpoint: string;
    numVisible: number;
    numScroll: number;
  }[] = [];

  constructor(
    private readonly _productGql:ProductGql
  ) { 
    super()
  }

  ngOnInit(): void {
    this.responsiveOptions = [
      {
        breakpoint: '1200px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1,
      }
    ];  

    this.findAllByCategorySlug( this.categorySlug ||  "insurance-option" );
  }

  findAllByCategorySlug(categorySlug:string){

    this._productGql.findByCategorySlug(categorySlug).subscribe({
      next: ({ data, loading }) => {
        this.busy = loading;
        console.debug("WidgetProductComponent graph loading ", loading);
        if (!this.busy) {
          const products:any[] = _.get(data, "products.data");
          console.debug("WidgetProductComponent product insurance option ", products);

          const mappedProducts: any[] = _.map( products, (item) => {
            console.debug("WidgetProductComponent mapping ", item)
            return {
              // this.asset(_.get( item, "attributes.Thumbnail.data.attributes.url"))
              icon: this.asset(_.get( item, "attributes.Images.data[0].attributes.url")),
              name: _.get(item,"attributes.Title"),
              price: _.get(item,"attributes.Price"),
              ctaText: _.get(item,"attributes.Text"),
              ctaRoute:`/get-a-quote/${_.get( item, "attributes.Slug")}`,
              routerLink: (_.get( item, "attributes.Slug")!=='device-insurance')?`/device-insurance/${_.get( item, "attributes.Slug")}`:"/device-insurance"
            };
          });
          console.debug("WidgetProductComponent mapped products ", mappedProducts);
          this.items = mappedProducts;
        }
      },
      error: (error) => {
        console.error("graph request failed ", error);
      },
      complete: () => {
        this.busy = false;
        console.debug("busy ", this.busy);
      }
    });

  }

}
