import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
@Component({
  selector: 'component-list',
  templateUrl: './component-list.component.html',
  styleUrls: ['./component-list.component.scss']
})
export class ComponentListComponent implements OnInit {

  @Input() element: any;
  @Input() itemsKey: string = "Items";
  @Input() headerStyle: string = "text-dark fw-normal fs-5d mb-4";
  @Input() removeBullets:boolean = false;
  Title: string | undefined;
  Summary: string | undefined;
  Items: any[] | undefined;


  constructor() { }

  ngOnInit(): void {
    console.debug("ComponentListComponent element ", this.element);
    this.Title = _.get(this.element, "Title");
    this.Summary = _.get(this.element, "Summary");
    this.Items = _.get(this.element, this.itemsKey);
  }

}
