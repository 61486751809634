<footer class="bg-primary pb-lg-5">
    <!-- Guard risk strip -->
    <div class="bg-blue text-center p-3 p-lg-4">
        <img src="/assets/guardrisk-white.svg" alt="GUARDRISK Insurance Company Limited" class="img-fluid">
    </div>

    <!-- Site map and logo with details -->
    <div class="container py-4 py-lg-5">
        <div class="row g-3 g-lg-4">
            <div class="col-12 col-lg-auto order-3 order-lg-0 text-center text-lg-start">
                <a routerLink="/"><img srcset="/assets/techsured_logo.png, /assets/techsured_logo@2x.png 2x" src="/assets/techsured_logo.png" alt="Techsured Logo" width="68" height="71"></a>
                <div class="d-flex justify-content-center justify-content-lg-start align-items-center gap-3 mt-3 mb-4">
                    <a href="https://www.facebook.com/TechsuredInsurance/" class="text-white opacity-40" target="_blank">
                        <i class="pi pi-facebook fs-5"></i>
                    </a>
                    <!--<a href="#" class="text-white opacity-40" target="_blank">
                        <i class="pi pi-twitter fs-5"></i>
                    </a>-->
                    <a href="https://www.linkedin.com/company/89319577/" class="text-white opacity-40" target="_blank">
                        <i class="pi pi-linkedin fs-5"></i>
                    </a>
                </div>
                <a href="https://guardrisk.co.za/" class="bg-white rounded-pill py-2 px-3" target="_blank">
                    <img src="/assets/guardrisk_logo.jpg" alt="GUARDRISK Logo" class="img-fluid" width="163" height="27">
                </a>
                <p class="mb-0 mt-4 text-white fs-x-small opacity-34">Copyright Techsured 2022</p>
            </div>

            <div class="col-6 col-md-4 col-lg-3 col-xl-2 ms-auto">
                <h6 class="fw-bold text-white opacity-40 mb-3">Legal</h6>
                <a role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" routerLink="/disclaimer">Disclaimer</a>
                
                <a *ngIf="downloadables|find:{'identifier':'pdf_privacy_policy'}" target="_blank" role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" 
                [href]="downloadables|find:{'identifier':'pdf_privacy_policy'}|attribute:'url'">Privacy Policy</a>
                
                <a *ngIf="downloadables|find:{'identifier':'pdf_paia_manual'}" target="_blank" role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" 
                [href]="downloadables|find:{'identifier':'pdf_paia_manual'}|attribute:'url'">PAIA Manual</a>

                <a role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" target="_blank" routerLink="/tcf-policy">TCF Policy</a>
                
                <a *ngIf="downloadables|find:{'identifier':'pdf_cookie_policy'}" target="_blank" role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" 
                [href]="downloadables|find:{'identifier':'pdf_cookie_policy'}|attribute:'url'">Cookie Policy</a>

                
                <a *ngIf="downloadables|find:{'identifier':'pdf_disclosure_notice'}" target="_blank" role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" 
                [href]="downloadables|find:{'identifier':'pdf_disclosure_notice'}|attribute:'url'">Disclosure Notice</a>

                <a *ngIf="downloadables|find:{'identifier':'pdf_complaints_framework'}" target="_blank" role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" 
                [href]="downloadables|find:{'identifier':'pdf_complaints_framework'}|attribute:'url'">Complaints Framework</a>
                

                <a *ngIf="downloadables|find:{'identifier':'pdf_conflict_of_interest'}" target="_blank" role="button" class="d-block text-decoration-none fs-x-small text-white mb-1" 
                [href]="downloadables|find:{'identifier':'pdf_conflict_of_interest'}|attribute:'url'">Conflict of Interest Policy</a>
            </div>

            <div class="col-6 col-md-4 col-lg-3 pe-xxl-5">
                <h6 class="fw-bold text-white opacity-40 mb-3">Insured by:</h6>
                <p class="text-white fs-xx-small mb-3">Guardrisk Insurance Company Limited <br>An authorised Financial Services Provider (FSP Number: 75) and a licensed non-life Insurer VAT Registration Number: 4250138072 </p>
                <p class="text-white fs-xx-small">Address: The MARC, Tower 2, 129 Rivonia Road, Sandton, 2196 </p>
            </div>

            <div class="col-12 col-md-4 col-lg-3 pe-xxl-5">
                <h6 class="fw-bold text-white opacity-40 mb-3">Administered by:</h6>
                <p class="text-white fs-xx-small mb-3">Viva Cover (Pty) Ltd <br>An authorised Financial Services Provider (FSP Number: 42787) VAT Registration Number: 4530243569, </p>
                <p class="text-white fs-xx-small">Company Registration Number: 2007/018990/07 Address: The Centenary Building, 30 Meridian Drive, Umhlanga, KwaZulu-Natal 4319 </p>
            </div>
        </div>
    </div>
</footer>