import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'component-step-group',
  templateUrl: './component-step-group.component.html',
  styleUrls: ['./component-step-group.component.scss']
})
export class ComponentStepGroupComponent implements OnInit {

  @Input() element: any;

  Steps: any[] | undefined;
  Title: String | undefined;
  Summary: String | undefined;

  constructor() { }

  ngOnInit(): void {
    console.debug("element ", this.element);
    this.Title = _.get(this.element, "Title");
    this.Summary = _.get(this.element, "Summary");
    this.Steps = _.get(this.element, "Steps");
  }

}
