<ng-container *ngIf="icons">

  <ng-container *ngIf="dashedSeperated===true">
    <div class="row justify-content-center">
      <div class="col-12 col-lg-10 col-xl-9 col-xxl-8">
        <div class="d-flex flex-column flex-md-row align-items-center align-items-md-start">
          <ng-container *ngFor="let icon of icons; let index=index; let last=last; let first=first">
            <div class="col-12 col-md-3 mt-4">
              <img src="/assets/icons/{{icon.Icon}}" alt="{{icon.Title}}" role="presentation" aria-hidden="true"
                width="46" height="46">
              <p class="mt-4 mb-0 {{(textStyle)?textStyle:''}}" *ngIf="!last">{{icon.Title}}</p>
              <h4 class="mt-4 mb-0 fw-bold text-secondary" *ngIf="last">{{icon.Title}}</h4>
            </div>
            <hr class="dashed-separator border-secondary flex-fill mobile-vr" *ngIf="!last">
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="dashedSeperated===false">
    <div class="row justify-content-center g-4 mt-4">
      <ng-container *ngFor="let icon of icons; let index=index; let last=last; let first=first">
        <div class="col-12 col-md-4 col-xxl-3 text-center">
          <img src="/assets/icons/{{icon.Icon}}" alt="{{icon.Title}}" width="77" height="67" role="presentation"
            aria-hidden="true">
          <h5 class="fw-bold text-dark mt-4 mb-3">{{icon.Title}}</h5>
          <span>{{icon.Text}}</span>
        </div>
      </ng-container>

    </div>
  </ng-container>

</ng-container>