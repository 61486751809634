import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'component-product-carousel',
  templateUrl: './component-product-carousel.component.html',
  styleUrls: ['./component-product-carousel.component.scss']
})
export class ComponentProductCarouselComponent implements OnInit {

  @Input() element: any;
  @Input() id:string | undefined;
  @Input() showPrice:boolean = true;

  items: any[] | undefined;

  responsiveOptions: {
    breakpoint: string;
    numVisible: number;
    numScroll: number;
  }[] = [];

  constructor() { }

  ngOnInit(): void {
    
    console.debug("ComponentProductCarouselComponent element ", this.element, " id ", this.id);
    this.items = _.get(  this.element, "Products.data" );
    console.debug( "ComponentProductCarouselComponent items ", this.items, " id ", this.id);

    this.responsiveOptions = [
      {
        breakpoint: '1200px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '768px',
        numVisible: 1,
        numScroll: 1,
      }
    ];
  }
}