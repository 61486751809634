import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

@Component({
  selector: 'component-product-card',
  templateUrl: './component-product-card.component.html',
  styleUrls: ['./component-product-card.component.scss']
})
export class ComponentProductCardComponent implements OnInit {

  @Input() item:any;
  features:any[] | undefined;
  
  constructor(private readonly _router:Router) { }

  ngOnInit(): void {
    console.debug("ComponentProductCardComponent ", this.item);
    this.features = _.get(this.item, "attributes.Features.Items" ); 
  }

  route(item:any){
    const routePath:string = (_.get( item, "attributes.Slug")!=='device-insurance')?`/device-insurance/${_.get( item, "attributes.Slug")}`:"/device-insurance";
    this._router.navigateByUrl(routePath);
  }

}
