<ng-container *ngIf="articles">
    <ng-container [ngSwitch]="display">
        <ng-container *ngSwitchCase="'Card'">
            <ng-container *ngTemplateOutlet="card;"></ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="'Button'">
            <ng-container *ngTemplateOutlet="button;"></ng-container>
        </ng-container>
    </ng-container>

    <ng-template #button>
        <p-carousel [value]="articles" [numVisible]="3" [numScroll]="1" [responsiveOptions]="responsiveOptions">
            <ng-template let-article pTemplate="item">
                <div class="mx-3 py-4">
                    <a role="button" class="card text-decoration-none border-0" [routerLink]="article.url">
                        <component-image [element]="article.thumbnail" styleClass="card-img-top"></component-image>
                        <div class="card-body">
                            <a role="button" [routerLink]="article.url"><h4 class="fw-bold text-dark">{{article.cardHeading}}</h4></a>
                            <div class="d-flex align-items-center mt-3">
                                <p-chip label="{{article.tag}}"
                                    styleClass="text-uppercase rounded-0 fs-xx-small text-info bg-info"></p-chip>
                                <div class="ms-auto text-dark opacity-40 fs-xx-small text-end">
                                    <span class="d-block fw-bold">{{article.createdAt|date:'MMMM d, yyyy'}}</span>
                                    <span class="d-block fw-bold">{{article.estimatedReadTime}}&nbsp;{{article.estimatedReadTimeMetric}}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </ng-template>
        </p-carousel>
    </ng-template>
    <ng-template #card>
        <p-carousel [value]="articles" [numVisible]="2" [numScroll]="1" [responsiveOptions]="responsiveOptions">
            <ng-template let-article pTemplate="item">
                <div class="mx-4 py-4">
                    <div class="card border-0">
                        <component-image [element]="article.thumbnail" styleClass="card-img-top"></component-image>
                        <div class="card-body p-sm-4">
                            <p-chip label="{{article.tag}}"
                                styleClass="text-uppercase rounded-0 fs-x-small text-info bg-info">
                            </p-chip>
                            <h3 class="fs-3b fw-bold text-dark mt-3">{{article.cardHeading}}</h3>
                            <div
                                class="d-flex flex-column flex-sm-row flex-wrap align-items-sm-center justify-content-sm-between mt-3">
                                <div class="d-flex align-items-center gap-2">
                                    <p-avatar size="large" shape="circle">
                                        <img src="{{article.authorPhoto}}" alt="{{article.author}} profile image">
                                    </p-avatar>                                    
                                    <p class="text-dark mb-1 fw-bold">by {{article.author}}</p>
                                </div>
                                <button pButton class="btn btn-link fs-7 mt-4 mt-sm-0 align-self-end align-self-sm-auto"
                                    label="Read more" [routerLink]="article.url">
                                    <img src="/assets/icons/icon-check-right-thin.svg" alt="Arrow pointing right"
                                        role="presentation" aria-hidden="true" width="10.56" height="22"
                                        class="order-1">
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>
    </ng-template>
</ng-container>