<section class="hero-banner" style="--background-image: url('{{BackgroundImageUrl}}')">
    <div class="hero-banner-gradient min-vh-100 d-flex flex-column justify-content-center">
        <div class="container mb-5 my-md-5">
            <div class="row hero-content-row">
                <div class="col-12 col-md-9 col-lg-8 col-xl-7 col-xxl-6">
                    <ng-container *ngIf="Icon">
                        <div class="d-flex align-items-center gap-3 mt-4 mt-sm-0 mb-4">
                            <ng-container *ngIf="Icon">
                                <img [src]="Icon" alt="{{Icon}}" role="presentation" aria-hidden="true" width="60.07" height="72.5">
                            </ng-container>
                        </div>
                    </ng-container>
                    <h1 class="fw-bold text-primary" emphasisLastWord="text-primary">{{Title}}</h1>
                    <p class="mt-3 mb-4">{{Text}}</p>
                    <ng-container *ngIf="Button">
                        <button pButton class="btn btn-primary" [label]="Button.Label" (click)="route(Button.Route)"></button>
                    </ng-container>
                    <img src="/assets/guardrisk.svg" alt="GUARDRISK Insurance Company Limited" width="350px" height="auto" class="d-block img-fluid mt-5 pe-2 lazyload">
                </div>
            </div>
        </div>
    </div>
</section>