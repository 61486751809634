import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'component-author-badge',
  templateUrl: './component-author-badge.component.html',
  styleUrls: ['./component-author-badge.component.scss']
})
export class ComponentAuthorBadgeComponent implements OnInit {

  @Input() data:any;

  constructor() { }

  ngOnInit(): void {
    console.debug( "ComponentAuthorBadgeComponent ", this.data)
  }

}
