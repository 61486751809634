import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'component-intellicell-nav',
  templateUrl: './component-intellicell-nav.component.html',
  styleUrls: ['./component-intellicell-nav.component.scss']
})
export class ComponentIntellicellNavComponent implements OnInit {

  @Output() itemClickedEvent = new EventEmitter<MouseEvent>();
  
  // To add "active" class to menu item
  activeMenuItem: boolean = false;

  // Toggle mobile nav
  showMobileNav: boolean = false;
  toggleNav = () => {
    this.showMobileNav = !this.showMobileNav;
  }

  // Scroll to Element function
  scrollToElement = (target: HTMLElement) => {
    target.scrollIntoView({
      behavior: "smooth"
    });
  }

  constructor() { }

  ngOnInit(): void {
  }

  itemClicked(subject:any){
    console.debug("item clicked target ", subject.target);
    this.itemClickedEvent.emit(subject);
  }

}
