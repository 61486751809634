import { Component, OnInit } from "@angular/core";
import * as _ from "lodash";
import { DownloadableGql } from "ngx-techsured";

@Component({
  selector: "component-footer",
  templateUrl: "./component-footer.component.html",
  styleUrls: ["./component-footer.component.scss"],
})
export class ComponentFooterComponent implements OnInit {
  downloadables: any[] | undefined;
  constructor(private readonly _downloadableGQL: DownloadableGql) {}

  ngOnInit(): void {
    this._downloadableGQL.findAll().subscribe({
      next: ({ loading, data }) => {
        if (!loading) {
          this.downloadables = _.get(data, "downloadables.data");
          this.downloadables = _.map(this.downloadables, (item) => {
            const identifier = _.get(item, "attributes.identifier");
            let url = _.get(item, "attributes.asset.data.attributes.url");

            // Check for specific identifiers and replace the URL if necessary
            if (identifier === "pdf_privacy_policy") {
              url =
                "https://policies.ignitiongroup.co.za/uploads/Data_Privacy_Statement_6db0863242.pdf";
            } else if (identifier === "pdf_cookie_policy") {
              url =
                "https://policies.ignitiongroup.co.za/uploads/cookiepolicy_b36bc1bd41.pdf";
            } else if (identifier === "pdf_paia_manual") {
              url =
                "https://policies.ignitiongroup.co.za/uploads/PAIA_Manual_357300275e.pdf";
            }

            return {
              identifier: identifier,
              url: url,
            };
          });
        }
      },
    });
  }
}
