<ng-container *ngIf="items">
  <p-carousel [value]="items" [showIndicators]="false" [numVisible]="3" [numScroll]="1"
    [responsiveOptions]="responsiveOptions" styleClass="product-carousel">
    <ng-template let-item pTemplate="item">
      <div class="carousel-card-container mx-4">
        <div class="card border-0 rounded-0 rounded-bottom border-top border-secondary border-2">
          <div class="card-body p-0">
            <div class="d-flex flex-column align-items-center px-3 px-md-4 pt-4 gap-3">
              <img src="{{item.icon}}" alt="Icon" height="60" width="60" role="presentation" aria-hidden="true">
              <h4 class="fw-bold text-dark mb-4 text-center">{{item.name}}</h4>
              <div class="d-flex align-items-end text-primary">
                <h6 class="fw-normal me-2">FROM </h6>
                <h2 class="fw-bold lh-xs">R{{item.price}}</h2>
                <h6 class="fw-bold">pm</h6>
              </div>
              <button pButton class="btn btn-sm bg-white btn-outline-primary w-100"
                label="{{item.ctaText}}" [routerLink]="item.ctaRoute"></button>
            </div>
            <div class="card-footer-light px-3 px-md-4 pb-4 text-center">
              <button pButton class="mt-3 btn btn-link" label="Learn more" [routerLink]="[item.routerLink]">
                <img src="/assets/icons/icon-check-right-thin.svg" alt="Arrow pointing right" role="presentation"
                  aria-hidden="true" width="7" height="14.59" class="order-1">
              </button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </p-carousel>
</ng-container>