import { Component, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { FrequentlyAskedQuestionGql, Widget } from 'ngx-techsured';

@Component({
  selector: 'widget-frequently-asked-question',
  templateUrl: './widget-frequently-asked-question.component.html',
  styleUrls: ['./widget-frequently-asked-question.component.scss']
})
export class WidgetFrequentlyAskedQuestionComponent extends Widget implements OnInit {

  frequentlyAskedQuestions: any[] | undefined;

  @Input() Tags: string[] | undefined;

  constructor(
    private readonly _frequentlyAskedQuestionGql: FrequentlyAskedQuestionGql
  ) {
    super();
  }

  ngOnInit(): void {
    if (this.Tags) {
      this.findAllByTags(this.Tags)
    } else {
      this.findAll();
    }
  }

  findAll() {
    this._frequentlyAskedQuestionGql.findAll().subscribe({
      next: (response) => {
        this.busy = response.loading;
        if (!this.busy) {
          this.frequentlyAskedQuestions = _.get(response, "data.frequentlyAskedQuestions.data");
          console.debug("frequentlyAskedQuestions ", this.frequentlyAskedQuestions);
        }
      },
      error: (error) => {
        console.error("failed to find fequently asked questions ", error)
      },
      complete: () => {
        this.busy = false;
      }
    });
  }

  findAllByTags(tags: string[]) {
    this._frequentlyAskedQuestionGql.findAllByTags(tags).subscribe({
      next: (response) => {
        this.busy = response.loading;
        if (!this.busy) {
          this.frequentlyAskedQuestions = _.get(response, "data.frequentlyAskedQuestions.data");
          console.debug(tags, "frequentlyAskedQuestions ", this.frequentlyAskedQuestions);
        }
      },
      error: (error) => {
        console.error("failed to find fequently asked questions ", error)
      },
      complete: () => {
        this.busy = false;
      }
    });
  }
}
