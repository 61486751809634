import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'find'
})
export class PipeFindPipe implements PipeTransform {

  transform(subject: any, query: any): any {
    const results: any = _.find(subject, query);
    // console.debug("find value ", subject, " query ", query);
    // console.debug(query, " results ", results);
    return results;
  }
}
